import React from 'react'
import ListIcon from './ListIcon'

const ListComp = ({ storeLength }: {storeLength?: number}) => {
  return (
    <div className="relative">
      <ListIcon />
      {!!storeLength && <div className="absolute bg-white rounded-full h-6 w-6 flex items-center justify-center -right-2 -bottom-2"><span className="text-viking text-xs font-bold">{storeLength}</span></div>}
    </div>
  )
}

export default ListComp;
