import React from "react"
import { IProductCardTitleOnly } from '../../../types/components'
 
import ProductCard from "./ProductCard"

const TitleOnlyContentCard: React.FC<IProductCardTitleOnly> = ({ 
  imgUrl, imgAlt, cardTitle, _bottomStyle = '', className = '', button, readMoreLink = '', ...rest }) => (
  <ProductCard imgAlt={imgAlt} imgUrl={imgUrl} cardTitle={cardTitle} button={button}
  _bottomStyle={_bottomStyle} className={className} cardContent='' cardSubtitle='' readMoreLink={readMoreLink} {...rest} />
)
export default TitleOnlyContentCard