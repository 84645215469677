import React from 'react'
import { IButton } from '../../../types/components'

const _size = {
  'btn-small': 'px-12',
  'btn-large': 'px-12 md:px-20',
}

const _color = {
  'btn-primary': 'bg-blue-stone',
  'btn-secondary': 'bg-viking',
  'btn-default': 'bg-white'
}

const Button: React.FC<IButton & React.HTMLAttributes<HTMLButtonElement>> = ({
  type = 'button',
  children,
  className = '',
  color = 'btn-primary',
  size = 'btn-small',
  disabled=false,
  ...rest
}) => {

  return (
    <>
      <button
        type={type}
        disabled={disabled}
        className={`btn-base text-white
       ${_size[size]}
       ${_color[color]}
       ${className}`}
        {...rest}>
        {children}
      </button>
    </>
  )
};

export default Button;
