import React from 'react'

export default function Remove() {
  return (
    <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>icon/remove</title>
    <g id="icon/remove" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g fill="#DD1010" fillRule="nonzero">
            <path d="M20,40 C8.97166667,40 0,31.0283333 0,20 C0,8.97166667 8.97166667,0 20,0 C31.0283333,0 40,8.97166667 40,20 C40,31.0283333 31.0283333,40 20,40 Z M20,1.66666667 C9.89166667,1.66666667 1.66666667,9.89166667 1.66666667,20 C1.66666667,30.1083333 9.89166667,38.3333333 20,38.3333333 C30.1083333,38.3333333 38.3333333,30.1083333 38.3333333,20 C38.3333333,9.89166667 30.1083333,1.66666667 20,1.66666667 Z" id="Shape"></path>
            <path d="M13.4279541,27.4033149 C13.2152556,27.4033149 13.0025571,27.3218889 12.8397098,27.1606987 C12.5156769,26.8366566 12.5156769,26.3098804 12.8397098,25.9858383 L25.7644687,13.0607112 C26.0885016,12.736669 26.6152628,12.736669 26.9392958,13.0607112 C27.2633287,13.3847533 27.2633287,13.9115295 26.9392958,14.2355717 L14.0145368,27.1606987 C13.8533512,27.3218889 13.6406527,27.4033149 13.4279541,27.4033149 Z" id="Path"></path>
            <path d="M26.3527131,27.1823204 C26.1400146,27.1823204 25.927316,27.1009037 25.7644687,26.9397319 L12.8397098,14.0144157 C12.5156769,13.6904104 12.5156769,13.1636942 12.8397098,12.839689 C13.1637427,12.5156838 13.6905039,12.5156838 14.0145368,12.839689 L26.9392958,25.7633437 C27.2633287,26.0873489 27.2633287,26.6140651 26.9392958,26.9380703 C26.7781102,27.1009037 26.5654116,27.1823204 26.3527131,27.1823204 L26.3527131,27.1823204 Z" id="Path"></path>
        </g>
    </g>
</svg>
  )
}
