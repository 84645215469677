import React from 'react';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';
import List from '../../molecules/ListComp';
import Search from '../../molecules/Search';
// import DropIcon from '../../atoms/svg/DropIcon';
import { navigate } from '@reach/router';
import { useAppListContext } from '../../../Context/AppListContext';

interface Props {
  showFilter?: boolean;
  manageFilterToggle?: (inp: any) => void;
  search: string;
  setSearch: any;
  closeFilterMenu?: any;
}

const SearchHeader: React.FC<Props> = ({ search, setSearch, closeFilterMenu}) => {
  const{ storeLength } = useAppListContext();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };


  const onSearch = () => {
    navigate(`/products/iplatform/search?q=${search}`);
    closeFilterMenu?.();
  }

  return (
    <div id="search-header" className="bg-viking p-6 w-full sticky z-40 top-27 lg:top-14">
      <div className="flex justify-center flex-col sm:flex-row items-center">
        <Search onSearch={onSearch} name="search" value={search} onChange={handleChange} placeholder="Search the iPlatform" />
        <div className="flex items-center mt-4 sm:mt-0 ml-4">
          <Link to="/products/iplatform/enquire">
            <div style={{minWidth: "7em"}} className="flex items-center cursor:pointer">
              <Text type="P" ptype="p-base" className="text-white leading-6 px-2 pb-0 mb-0">My List :</Text>
              <List storeLength={storeLength} />
            </div>
          </Link>
          {/* <button disabled={!storeLength} onClick={clearCart} className={`text-sm text-bold ml-4 lg:ml-8 whitespace-nowrap text-white leading-6 mb-0 border-solid pb-0 border-white ${storeLength ? 'hover:border-b cursor-pointer' : 'opacity-40 cursor-not-allowed'}`}>
            CLEAR LIST
          </button> */}
        </div>
 
      </div>        
      {/* <div style={{width: 'fit-content'}} onClick={manageFilterToggle} className="flex mt-8 mx-auto justify-center items-center cursor-pointer">
          <Text type="P" ptype="p-base" className="text-white leading-6 px-2 pb-0 mb-0">{`${showFilter ? 'HIDE' : 'SHOW'} ALL FILTERS`}</Text>
          <DropIcon />
        </div> */}
    </div>
  )
}

export default SearchHeader
