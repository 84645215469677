import React, { FunctionComponent } from "react";
import ReactPaginate from 'react-paginate';
import Next from "./Next";
import Prev from "./Prev";
import './Pagination.css';

interface IPagination {
  count: number;
  pageSize: number;
  setPage: any;
  page: number;
}

const PaginationComp: FunctionComponent <IPagination>  = ({ count, setPage, pageSize, page }) => {

  const handlePageClick = (data: {selected: number}) => {
    let selected = data.selected;
    setPage(selected + 1)
  };
  const totalPages = Math.ceil(count/pageSize)
 const prevDisabled = page <= 1 ? true : false;
 const nextDisabled = page >= totalPages ? true : false;

  return (
    <div className='pagination-comp w-full flex items-center justify-center'>
       <ReactPaginate
          previousLabel={Prev({disabled: prevDisabled})}
          nextLabel={Next({disabled: nextDisabled})}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={totalPages}
          marginPagesDisplayed={1}
          forcePage={page - 1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
          pageClassName={'each-page'}
        />
      </div>
  );
};

export default PaginationComp;
