import React from 'react';
import Image from '../../atoms/Image'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import { Card } from '../../../types';
import { replaceLinks } from '../../../utils/service'
import PortableText from '../../atoms/PortableText'

interface IProp {
  imgUrl: any
  imgAlt: string
  _bottomStyle?: string,
  cardTitle: string
  cardSubtitle?: string
  className?: string
  cardContent: any
  readMoreLink: string
  button: Card['button']
}

const GroupCard: React.FC<IProp> = ({
  imgUrl, imgAlt = '', cardTitle, _bottomStyle = '', button, className = '', cardContent, cardSubtitle, readMoreLink, ...rest }) => {
    
  const _readMoreLink = button?.buttonHref ? button.buttonHref : replaceLinks(`/${button?.buttonLink?.slug?.current}`);
  const readMore = button?.buttonText || 'Read More'
  return (
    <article className={`border border-white-smoke shadow-lg flex flex-col justify-between pb-5 ${className}`} {...rest}>
      <div className="w-10/12 mx-auto">
        <div className="flex justify-center items-center h-44 border-b border-dark-grey">
          <Image alt={imgAlt} src={imgUrl} className="object-contain h-7/12" />
        </div>
        <div className={`my-8 ${_bottomStyle}`}>
          <Text type="H4" className="font-normal text-charcoal mb-2">{cardTitle}</Text>
          {cardSubtitle && (<Text type="P" ptype="sm-light" className="my-4"><span className="font-medium">Pillar:</span> {cardSubtitle}</Text>)}
          {cardContent && (typeof cardContent === 'string' ? (<Text type="P" ptype="sm-light">{cardContent}</Text>) :
            <PortableText className="text-lg" content={cardContent} template={{
              normal: (props: any) => <Text type="P" ptype="p-base" className="text-charcoal text-sm leading-6">{props.children}</Text>,
              h2: (props: any) => <Text type="H2" className="text-charcoal font-normal">{props.children}</Text>,
              h1: (props: any) => <Text type="H1" className="text-charcoal font-normal">{props.children}</Text>,
              blockquote: (props: any) => <Text type="Quote" className="">{props.children}</Text>,
              ul: (props: any) => <ul className="px-2">{props.children}</ul>,
              li: (props: any) => <li className="list-disc text-charcoal text-sm leading-6">{props.children}</li>,
            }} />)
          }
        </div>
      </div>
      <div className="w-10/12 mx-auto py-5">
        {_readMoreLink && <Link to={_readMoreLink} type={button?.buttonHref ? 'external':'internal'}
          className="bg-blue-stone rounded-full text-white py-2 border 
        border-blue-stone text-center block hover:bg-white hover:text-blue-stone 
        transition-all duration-700 lg:w-7/12">{readMore}</Link>}
      </div>
    </article>
  )
}

export default GroupCard