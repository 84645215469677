import React from 'react'
import Text from '../../atoms/Text'
import { CardWideType } from '../../../types';
import PortableText from '../../atoms/PortableText'
interface ICard {
  className?: string
  body?: CardWideType['body']
}

const CoreModulesCard: React.FC<ICard> = ({ body, className, ...rest }) => {
  return (
    <article className={`border w-full border-white-smoke flex flex-col justify-between py-5 px-5 text-gray-700  ${className}`} {...rest}>
      <PortableText className="inline" content={body} template={{
        normal: (props: any) => <Text type="P" ptype="p-base" className="text-sm py-3 text-charcoal font-light leading-7">{props?.children}</Text>,
        h3: (props: any) => <Text type="H4" className="font-medium font-montserrat mb-3">{props?.children}</Text>,
        h4: (props: any) => <Text type="H4" className="font-medium font-montserrat mb-3">{props?.children}</Text>,
      }} />
    </article>
  )
}

export default CoreModulesCard