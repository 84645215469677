import React from 'react'
import Text from '../../atoms/Text'
import {IPlatformCard} from '../../molecules/Card'
import { IPlatformCardProps } from '../../molecules/Card/IPlatformCard'

interface IPlatformCategoriesProps {
 records: IPlatformCardProps[];
 Header?: any;
 className?: string;
 view?: 'grid' | 'list';
 FilterTab?: any
 grid_3?: boolean
}

export interface CategoryContainerProps {
  CustomHeader?: any;
  view?: 'grid' | 'list';
}


const PlatformCategories: React.FC<IPlatformCategoriesProps> = ({ records, Header, className="", view = 'grid',  FilterTab, grid_3}) => {
  const gridStyle = `grid gap-8 grid-cols-1 ${grid_3 ? 'md:grid-cols-3': 'md:grid-cols-2'} justify-center`;
  const listStyle = "flex flex-col";
  const viewStyle = view === 'grid' ? gridStyle : listStyle;
  return (
    <>
    {<div id="productlist" className={`max-w-7xl px-4 mx-auto ${className || ""}`}>
     {Header && <Header />}
     <section className="lg:grid grid-cols-3 my-12 gap-8">
        <div className="">
          {FilterTab}
        </div>
        {!records?.length ? (<>
          <div className='col-span-2'>
            <Text type="P" ptype="sm-light" className="text-2xl text-center mx-auto py-12" >Result is empty</Text>
          </div>
        </>)
        :
        <div className={`${grid_3 ? 'col-span-3': 'col-span-2'} ${viewStyle}`}>
              {records?.map?.((rec: IPlatformCardProps, _, arr) => <IPlatformCard key={rec.uniqueReference} shouldUseMaxHeight={(arr.length <= 2)} {...rec} view={view} hideCategory />)}
        </div>}
     </section>
    </div>}
    </>
    
  )
}

export default PlatformCategories
