import React from 'react';

const LoginIcon = () => {
  return (
    <svg width="38px" height="36px" viewBox="0 0 38 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>icon/login/dual-colours</title>
      <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Our-Products-|-Our-Ecosystem--|--iPlatform" transform="translate(-165.000000, -951.000000)" fillRule="nonzero">
          <g id="Group-9-Copy-3" transform="translate(0.000000, 939.000000)">
            <g id="icon/login/dual-colours" transform="translate(165.000000, 12.000000)">
              <path d="M25.284375,18.9 L0.815625,18.9 C0.3654,18.9 0,18.4968 0,18 C0,17.5032 0.3654,17.1 0.815625,17.1 L25.284375,17.1 C25.7346,17.1 26.1,17.5032 26.1,18 C26.1,18.4968 25.7346,18.9 25.284375,18.9 Z" id="Path" fill="#4CC2B8"></path>
              <path d="M18.810324,25.2 C18.6029847,25.2 18.3956454,25.1215935 18.2369013,24.9663805 C17.9210329,24.6543545 17.9210329,24.1471123 18.2369013,23.8350863 L24.1444506,17.9993999 L18.2385211,12.1653138 C17.9226527,11.8532878 17.9226527,11.3460455 18.2385211,11.0340195 C18.5543896,10.7219935 19.0678782,10.7219935 19.3837466,11.0340195 L25.8630987,17.4345529 C26.1789671,17.7465789 26.1789671,18.2538212 25.8630987,18.5658472 L19.3837466,24.9663805 C19.2250025,25.1215935 19.0176632,25.2 18.810324,25.2 L18.810324,25.2 Z" id="Path" fill="#4CC2B8"></path>
              <path d="M19.7584884,36 C12.1236487,36 5.28755599,31.1776364 2.74698314,24.0005455 C2.59609049,23.5734545 2.82078932,23.1070909 3.24722505,22.9565455 C3.67530091,22.8092727 4.14274008,23.0301818 4.29363272,23.4556364 C6.6029462,29.9798182 12.8174269,34.3636364 19.7584884,34.3636364 C28.8022061,34.3636364 36.1598626,27.0229091 36.1598626,18 C36.1598626,8.97709091 28.8022061,1.63636364 19.7584884,1.63636364 C12.8174269,1.63636364 6.6029462,6.02018182 4.29527286,12.546 C4.14438022,12.9714545 3.67530091,13.1923636 3.24886519,13.0450909 C2.82242946,12.8945455 2.59773063,12.4281818 2.74862327,12.0010909 C5.28755599,4.82236364 12.1236487,0 19.7584884,0 C29.7059218,0 37.8,8.07545455 37.8,18 C37.8,27.9245455 29.7059218,36 19.7584884,36 Z" id="Path" fill="#404040"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LoginIcon
