import React from 'react'

interface Props {
  active: boolean
}

const GridIcon = (props: Props) => {
  const color = props.active ? '#4CC2B8' : '#404040';
  return (
<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
    <title>icon/grid-view/grey</title>
    <g id="icon/grid-view/grey" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M10.72,0.533333333 C10.72,0.238666667 10.4813333,0 10.1866667,0 L0.533333333,0 C0.238666667,0 0,0.238666667 0,0.533333333 L0,10.1866667 C0,10.4813333 0.238666667,10.72 0.533333333,10.72 L10.1866667,10.72 C10.4813333,10.72 10.72,10.4813333 10.72,10.1866667 L10.72,0.533333333 Z M9.65333333,9.65333333 L1.06666667,9.65333333 L1.06666667,1.06666667 L9.65333333,1.06666667 L9.65333333,9.65333333 Z" id="Shape" fill={color} fillRule="nonzero"></path>
        <path d="M24,0.533333333 C24,0.238666667 23.7613333,0 23.4666667,0 L13.8133333,0 C13.5186667,0 13.28,0.238666667 13.28,0.533333333 L13.28,10.1866667 C13.28,10.4813333 13.5186667,10.72 13.8133333,10.72 L23.4666667,10.72 C23.7613333,10.72 24,10.4813333 24,10.1866667 L24,0.533333333 Z M22.9333333,9.65333333 L14.3466667,9.65333333 L14.3466667,1.06666667 L22.9333333,1.06666667 L22.9333333,9.65333333 Z" id="Shape" fill={color} fillRule="nonzero"></path>
        <path d="M10.72,13.8133333 C10.72,13.5186667 10.4813333,13.28 10.1866667,13.28 L0.533333333,13.28 C0.238666667,13.28 0,13.5186667 0,13.8133333 L0,23.4666667 C0,23.7613333 0.238666667,24 0.533333333,24 L10.1866667,24 C10.4813333,24 10.72,23.7613333 10.72,23.4666667 L10.72,13.8133333 Z M9.65333333,22.88 L1.06666667,22.88 L1.06666667,14.3466667 L9.65333333,14.3466667 L9.65333333,22.88 Z" id="Shape" fill={color} fillRule="nonzero"></path>
        <path d="M24,13.8133333 C24,13.5186667 23.7613333,13.28 23.4666667,13.28 L13.8133333,13.28 C13.5186667,13.28 13.28,13.5186667 13.28,13.8133333 L13.28,23.4666667 C13.28,23.7613333 13.5186667,24 13.8133333,24 L23.4666667,24 C23.7613333,24 24,23.7613333 24,23.4666667 L24,13.8133333 Z M22.9333333,22.88 L14.3466667,22.88 L14.3466667,14.3466667 L22.9333333,14.3466667 L22.9333333,22.88 Z" id="Shape" fill={color} fillRule="nonzero"></path>
    </g>
</svg>
  )
}

export default GridIcon
