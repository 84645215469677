import React from 'react'
import Text from '../../atoms/Text'
import MaxWidth from '../../molecules/Maxwidth'
import LoginIcon from './LoginIcon'


const LoginHeader: React.FC = () => {

  return (
     <div className="w-full links p-4 bg-ash flex items-center hidden">
       <div className="mr-4">
         <LoginIcon />
       </div>
       <div className="flex">
       <Text type="P" ptype="p-base" className="text-charcoal leading-6 px-2 pb-0 mb-0 border-r border-1 border-solid border-black">Login</Text>       
       <Text type="P" ptype="p-base" className="text-charcoal leading-6 px-2 pb-0 mb-0">Register</Text>
       </div>     
     </div>
  )
}

export default LoginHeader