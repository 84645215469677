import React from 'react';
import PortableText, { tags } from '../../atoms/PortableText';
import Text from '../../atoms/Text';
import Link from '../../atoms/Link';

const addSpacing = (value: Boolean[]): Boolean => {
  if(!value?.[0]) return true;
  return false;
}

const PortableTextRender: React.FC<{
  content: any,
  template?: {
  [key in tags]?: any}
  className?: string
}> = ({ content, template = {}, className = '' }) => {

  const _template = {
    normal: (props: any) => <Text type="P" ptype="p-base"
    className={`text-dove-grey ${addSpacing(props?.children?.map?.(Boolean)) ? 'my-8': 'm-0'}`}
      >{props.children}</Text>,
    h1: (props: any) => <Text type="H1" className="text-charcoal font-normal">{props.children}</Text>,
    h2: (props: any) => <Text type="H2" className="text-charcoal font-normal">{props.children}</Text>,
    h3: (props: any) => <Text type="H3" className="text-charcoal font-normal my-4">{props.children}</Text>,
    h4: (props: any) => <Text type="H4" className="text-charcoal font-medium my-4">{props.children}</Text>,
    h5: (props: any) => <Text type="H5" className="text-charcoal font-normal my-4">{props.children}</Text>,
    h6: (props: any) => <Text type="H6" className="text-charcoal font-normal mb-5">{props.children}</Text>,
    span: (props: any) => <Text ptype="p-base" type="P" className="my-2">{props.children}</Text>,
    blockquote: (props: any) => <Text type="Quote"  className="">{props.children}</Text>,
    normalrow: (props: any) => {
      return <ul className="dash-style py-2">
        <li className='text-dove-grey border-b mb-1 text-base pl-2'>
        {props.children}
      </li>
      </ul>
    },
    ListRowrow: (props: any) => {
      return <ul className="dash-style py-2">
        <li className='text-dove-grey border-b mb-1 text-base pl-2'>
        {props.children}
      </li>
      </ul>
    },
    urow: (props: any) => {
      return <li className='text-dove-grey border-b mb-1 text-base p-2 list-none'>
        {props.children}
      </li>
    },
    ul: (props: any) => <ul className="ml-px list-inside list-disk">{props.children}</ul>,
    ol: (props: any) => <ol className="list-inside list-decimal">{props.children}</ol>,
    li: (props: any) => <li className="text-charcoal text-sm leading-6">{props.children}</li>,
    paddedpara: (props: any) => <Text type="P" ptype="p-base" className="text-dove-grey my-5">{props.children}</Text>,
    externalLink: (props: any) => <Link to={props.url} type="external" className="underline">{props.children}</Link>,
    internalLink: (props: any) => <Link to={props?.reference?.slug?.current || '#'}
    type="internal" className="underline cursor-pointer">{props.children}</Link>,
  };

  return <PortableText className={className} content={content} template={{..._template, ...template }} />
};

export default PortableTextRender;
