import React from 'react'

interface Props {
  active: boolean
}

const ListIcon = (props: Props) => {
  const color = props.active ? '#4CC2B8' : '#404040';
  return (
    <svg width="27px" height="24px" viewBox="0 0 27 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>icon/list-view/grey</title>
        <g id="icon/list-view/grey" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <path d="M2.85714286,5.71428571 C1.28114286,5.71428571 0,4.43314286 0,2.85714286 C0,1.28114286 1.28114286,0 2.85714286,0 C4.43314286,0 5.71428571,1.28114286 5.71428571,2.85714286 C5.71428571,4.43314286 4.43314286,5.71428571 2.85714286,5.71428571 Z M2.85714286,1.14285714 C1.912,1.14285714 1.14285714,1.912 1.14285714,2.85714286 C1.14285714,3.80228571 1.912,4.57142857 2.85714286,4.57142857 C3.80228571,4.57142857 4.57142857,3.80228571 4.57142857,2.85714286 C4.57142857,1.912 3.80228571,1.14285714 2.85714286,1.14285714 Z" id="Shape" fill={color} fillRule="nonzero"></path>
            <path d="M2.85714286,14.8571429 C1.28114286,14.8571429 0,13.576 0,12 C0,10.424 1.28114286,9.14285714 2.85714286,9.14285714 C4.43314286,9.14285714 5.71428571,10.424 5.71428571,12 C5.71428571,13.576 4.43314286,14.8571429 2.85714286,14.8571429 Z M2.85714286,10.2857143 C1.912,10.2857143 1.14285714,11.0548571 1.14285714,12 C1.14285714,12.9451429 1.912,13.7142857 2.85714286,13.7142857 C3.80228571,13.7142857 4.57142857,12.9451429 4.57142857,12 C4.57142857,11.0548571 3.80228571,10.2857143 2.85714286,10.2857143 Z" id="Shape" fill={color} fillRule="nonzero"></path>
            <path d="M2.85714286,24 C1.28114286,24 0,22.7188571 0,21.1428571 C0,19.5668571 1.28114286,18.2857143 2.85714286,18.2857143 C4.43314286,18.2857143 5.71428571,19.5668571 5.71428571,21.1428571 C5.71428571,22.7188571 4.43314286,24 2.85714286,24 Z M2.85714286,19.4285714 C1.912,19.4285714 1.14285714,20.1977143 1.14285714,21.1428571 C1.14285714,22.088 1.912,22.8571429 2.85714286,22.8571429 C3.80228571,22.8571429 4.57142857,22.088 4.57142857,21.1428571 C4.57142857,20.1977143 3.80228571,19.4285714 2.85714286,19.4285714 Z" id="Shape" fill={color} fillRule="nonzero"></path>
            <path d="M26.8571429,3.42857143 L8.57142857,3.42857143 C8.256,3.42857143 8,3.17257143 8,2.85714286 C8,2.54171429 8.256,2.28571429 8.57142857,2.28571429 L26.8571429,2.28571429 C27.1725714,2.28571429 27.4285714,2.54171429 27.4285714,2.85714286 C27.4285714,3.17257143 27.1725714,3.42857143 26.8571429,3.42857143 Z" id="Path" fill={color} fillRule="nonzero"></path>
            <path d="M26.8571429,12.5714286 L8.57142857,12.5714286 C8.256,12.5714286 8,12.3154286 8,12 C8,11.6845714 8.256,11.4285714 8.57142857,11.4285714 L26.8571429,11.4285714 C27.1725714,11.4285714 27.4285714,11.6845714 27.4285714,12 C27.4285714,12.3154286 27.1725714,12.5714286 26.8571429,12.5714286 Z" id="Path" fill={color} fillRule="nonzero"></path>
            <path d="M26.8571429,21.7142857 L8.57142857,21.7142857 C8.256,21.7142857 8,21.4582857 8,21.1428571 C8,20.8274286 8.256,20.5714286 8.57142857,20.5714286 L26.8571429,20.5714286 C27.1725714,20.5714286 27.4285714,20.8274286 27.4285714,21.1428571 C27.4285714,21.4582857 27.1725714,21.7142857 26.8571429,21.7142857 Z" id="Path" fill={color} fillRule="nonzero"></path>
        </g>
    </svg>
  )
}

export default ListIcon
