import React from 'react'
import CheckIcon from '../svg/CheckIcon';

interface Props {
  className?: string;
  label?: string | React.ReactNode;
  defaultChecked?: boolean;
  name: string;
  value?: string;
  handleChange: (e: any) => void;
  inpVal?: string;
  type?: 'checkbox'|'radio';
  checkIconFill?: string
}

const Checkbox: React.FC<Props> = ({ className="border-new-grey", checkIconFill, label, name, inpVal, handleChange, children, ...props }) => {
  const radioCheck = () => inpVal === props?.value && <CheckIcon fill={checkIconFill}/>;
  const checkBoxCheck = () => props?.defaultChecked && <CheckIcon fill={checkIconFill} />;
  const checkType = props?.type === 'checkbox' ? checkBoxCheck : radioCheck;
  return (
    <div 
      onClick={
        () => {         
        handleChange({target: {value: props.value, name}})
      }} 
      className="flex items-center justify-center cursor-pointer mr-6 mt-4 whitespace-nowrap"
    >
      <div className={`border-2 border-solid w-5 h-5 rounded-sm cursor-pointer flex items-center justify-center ${className}`}>
        {checkType()}
      </div>
      <label htmlFor={name} className="text-new-grey m-0 ml-2 p-0 text-lg relative cursor-pointer">{label || children}</label>
      <input 
        type="radio" 
        className="hidden" 
        name={name} 
        {...props} 
      />
    </div>
  )
}

export default Checkbox
