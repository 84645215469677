import React from 'react'
import Link from '../../atoms/Link'
import Text from '../../atoms/Text'
import { replaceLinks } from '../../../utils/service'
import { CardLink, Button } from '../../../types'

interface IProp {
  heading: string
  className?: string
  imageUrl: any
  link: CardLink[]
  button?: Button
}

const FeaturedCard: React.FC<IProp> = ({ heading, link,  className = '', imageUrl, button, ...rest }) => {
  return (
    <section className={`w-full h-60 overflow-hidden bg-center bg-cover bg-no-repeat ${className}`} style={{ 'backgroundImage': `url(${imageUrl})` }} {...rest}>
      <Link type={link?.[0]?.linkURL ? 'external' : 'internal'} to={button?.buttonLink?.slug?.current ? button?.buttonLink?.slug?.current! : link?.[0]?.linkURL ? link?.[0].linkURL : replaceLinks(`/${link?.[0]?.pageLink?.slug?.current}`)} className="h-full block p-5 bg-gradient-to-b from-black via-transparent to-transparent">
          <Text type="H5" className="text-white leading-normal">
            {heading}
          </Text>
      </Link>
    </section>
  )
}

export default FeaturedCard