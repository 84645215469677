import { useLocation, navigate } from '@reach/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IChip, useIPlatformContext } from '../../../Context/IPlatformContext'
import usePaginate from '../../../hooks/usePaginate';
// import Button from '../../atoms/Button'
import Text from '../../atoms/Text';
import PaginationComp from '../../molecules/Pagination';
import ViewComp from '../../molecules/ViewComp'
import { ISelectedChip } from '../ItemSelectWrapper'
import PlatformSearchResults from '../PlatformSearchResults'
import SearchHeader from '../SearchHeader';
import FilterTab from '../PlatformCategories/FilterTab';

interface IChipsContainerProps {
  showFilter: boolean;
}

const ChipsContainer: React.FC<IChipsContainerProps> = ({ showFilter }) => {
  const [view, setViewType] = React.useState<"list" | "grid">("grid");
  const { getChips, getFilteredProducts, store, getCategoryChipById, getSearchResult } = useIPlatformContext();
  const [selectedChipArr, setSelectedChipArr] = React.useState<ISelectedChip[]>([]);
  const [showApplyFilter, setShowApplyFilter] = React.useState<boolean>(true);
  const [searchVal, setSearch] = useState<string>("");
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const platformSearchResultRef = React.useRef(null);

  const toggleItemInList = (inp: ISelectedChip): void => {
      setShowApplyFilter(true);
      setSelectedChipArr(prev => {
        const item = prev?.find?.((ele: ISelectedChip) => ele.id === inp.id); 
        if(item) {
          return prev?.filter((ele: ISelectedChip) => ele.id !== inp.id);
        } else {
          return [...prev, inp];
        }      
      });
  };

  const isInSelectedList = (inp: IChip): boolean => {
    const item = selectedChipArr?.find?.((ele: ISelectedChip) => ele.id === inp.id);
    return item ? true : false;
  };
  const chipNames = useMemo(() => selectedChipArr?.map((item: ISelectedChip) => item.text)?.join?.(', ') || '', [selectedChipArr]);
  const [selectedChipNames, setSelectedChipName] = useState<string>(chipNames); 
 
  const scrollSearchElementInView = (elementId = '#search-header') => {
    if(platformSearchResultRef?.current) {
      // @ts-ignore
      const searchHeaderHight = document.querySelector(elementId)?.clientHeight;
      if(!searchHeaderHight) return;
      const stickyHeight = 48 + searchHeaderHight;
      const currentScrollPos = window.scrollY;
      // @ts-ignore
     const platformScrollTopoffset =  platformSearchResultRef.current?.getBoundingClientRect().top;
     const scrollToPos = currentScrollPos + platformScrollTopoffset - stickyHeight;
     window.scrollTo(0, scrollToPos);
    }
  }

  const chipListArr = useMemo(() => getChips?.() || [], [store])
  const [localShowFilter, setlocalShowFilter] = React.useState<boolean>(false);

  //get category id from url query
  const { search } = useLocation();
  const categoryId = new URLSearchParams(search).get('category');
  const searchQuery = new URLSearchParams(search).get('q');
  const { page, pageSize, paginated, setPage, processPagination, count, raw } = usePaginate(12, scrollSearchElementInView);


  const calculateFilter = (chipArr: any) => {
     searchQuery && setSearch("");
     showApplyFilter && setShowApplyFilter(false);
     const searchProducts = getFilteredProducts?.(chipArr);
     processPagination(searchProducts);
  };

  const handleFilter = useCallback(() => {
    !localShowFilter && setlocalShowFilter(true);
    calculateFilter(selectedChipArr);
    setSelectedChipName(chipNames);
  }, [calculateFilter, selectedChipArr, searchQuery, showApplyFilter]);

  useEffect(() => {
    handleFilter()
  }, [selectedChipArr])

  const manageFilterToggle = () => {
     if(!showFilter) {
       navigate(`/products/iplatform/search?q=${searchVal}`);
       !localShowFilter && setlocalShowFilter(true);
     } else {
        setlocalShowFilter(prev => !prev);
     }
  }


  const handleClearFilters = () => {
    selectedChipArr.length && setSelectedChipArr([]);
    calculateFilter([]);
    setSearch("");
    setSelectedChipName("");
    if(searchQuery) {
      navigate(`/products/iplatform/search`);
    }
  }

  const [initialChipCategory, setInitialChipCategory] = React.useState<boolean>(false);

  useEffect(() => {        
    if (searchQuery) {
      const searchProducts = getSearchResult?.(searchQuery || '');
      processPagination(searchProducts);
      setSearch(searchQuery);
      setSelectedChipArr([]);
      localShowFilter && setlocalShowFilter(false);
    } else if (categoryId && getCategoryChipById && !initialChipCategory && store?.categories)  {
      const item = getCategoryChipById(categoryId);    
      setSelectedChipArr([item]);
      calculateFilter([item]);
      setInitialChipCategory(true);
    } else {
      handleFilter();
    }
  }, [searchQuery, store, categoryId]);

  // const shouldDisableClearFilters = useMemo(() => {
  //    return (store?.products?.list?.length === raw?.length && !selectedChipArr?.length)
  // }, [store, raw, searchVal, selectedChipArr]);
  
  
  const SearchResultHeader = useCallback(() => (
    <>
    <button className={`hover:bg-viking hover:text-white hover:border-viking 
    md:w-auto md:px-16 block text-viking lg:hidden
    border border-viking rounded-full py-2 w-full mb-6`} onClick={() => setIsFilterOpen(true)}>Filter</button>
    
    <div className="flex w-full justify-between items-center flex-wrap">
      <div className="flex items-center flex-wrap">
        <Text type="H3" className="text-viking mr-4 my-0 p-0 mb-2">Results for:</Text>
        <Text ptype="p-base" type="P" className="mr-4 font-light mb-2 text-sm max-w-xs sm:max-w-sm md:max-w-xl whitespace-nowrap overflow-x-hidden overflow-ellipsis">{selectedChipNames || 'All'}</Text>
        <Text type="P" ptype="sm-light" className="mb-2">{`${raw?.length || 'No'} apps`}</Text>
      </div>
      <div className="mb-2"><ViewComp setView={setViewType} activeView={view} /></div>
    </div>
    </>
  ), [raw?.length, view, selectedChipNames]);
  return (
    <>
      <SearchHeader closeFilterMenu={() => localShowFilter && setlocalShowFilter(false)} search={searchVal} setSearch={setSearch} showFilter={showFilter && localShowFilter} manageFilterToggle={manageFilterToggle} />
      {showFilter && <div className="max-w-7xl border border-solid border-viking p-8 px-4 sm:px-8 py-12 mx-auto pb-4">
       
        {/* <div className="flex items-center justify-center flex-col">
          <div className="w-full flex items-center justify-center flex-col">
            {showApplyFilter && <Button onClick={handleFilter} type="button" size="btn-large" color="btn-secondary" className="rounded-3xl py-2 px-4 mx-auto w-full max-w-sm" > Apply All Filters</Button>}
            <button 
              disabled={shouldDisableClearFilters}
              onClick={handleClearFilters} 
              type="button" color="btn-secondary" 
              className={`rounded-3xl py-2 px-4 mt-4 mx-auto w-full max-w-sm bg-transparent text-viking border border-solid border-viking ${shouldDisableClearFilters ? 'opacity-40 cursor-not-allowed' : ''}`} 
            >
              Clear All Filters
            </button>
          </div>

          {localShowFilter && <div onClick={() => setlocalShowFilter(false)}><Text type="P" ptype="p-base" className="text-viking leading-6 px-2 pb-0 mb-0 mt-8 cursor-pointer">SHOW LESS FILTERS</Text></div>}
        </div> */}
        <div id="searchList" className='pt-12' ref={platformSearchResultRef}>  
            <div>
              <PlatformSearchResults content={paginated} view={view} CustomHeader={SearchResultHeader}
              FilterTab={
              <FilterTab 
                chipListArr={chipListArr} 
                isInSelectedList={isInSelectedList}
                toggleItemInList={toggleItemInList}
                handleClearFilters={handleClearFilters}
                isFilterOpen={isFilterOpen}
                setIsFilterOpen={setIsFilterOpen}
              />} />
              {!paginated?.length ? null : <PaginationComp page={page} pageSize={pageSize} setPage={setPage} count={count} />}
            </div>
        </div>    
      </div>
      }
    </>
  )
}

export default ChipsContainer
