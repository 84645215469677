import React from 'react';

const SearchIcon: React.FC = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Search Icon</title>
        <defs>
            <rect id="path-1" x="0" y="0" width="1440" height="145"></rect>
            <filter x="-3.0%" y="-15.5%" width="106.0%" height="160.0%" filterUnits="objectBoundingBox" id="filter-2">
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"></feMorphology>
                <feOffset dx="0" dy="21" in="shadowSpreadOuter1" result="shadowOffsetOuter1"></feOffset>
                <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.0569174173 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
            </filter>
            <rect id="path-3" x="0" y="0" width="24" height="24"></rect>
            <path d="M21.8336433,20.2158048 L16.6588204,15.0027194 C16.464536,14.807293 16.4451075,14.5061535 16.5953922,14.2752989 C18.7170925,11.021049 18.2222386,6.54566983 15.0919736,3.87255645 C12.0799935,1.30115624 7.49545235,1.38915527 4.57718591,4.06798287 C1.22749376,7.1428061 1.14292289,12.3764627 4.32461615,15.5604275 C7.03774109,18.2758262 11.2371419,18.6055368 14.3211215,16.5655593 C14.5525486,16.4124182 14.8559751,16.4324179 15.0514024,16.6289872 L20.2147969,21.8312155 C20.438224,22.0557845 20.8005073,22.0563559 21.0245058,21.8323584 L21.8325005,21.0237959 C22.0553562,20.8009412 22.0559276,20.4398023 21.8336433,20.2158048 L21.8336433,20.2158048 Z M5.94060548,13.9427311 C3.73262007,11.7330412 3.73262007,8.13765227 5.94060548,5.92796236 C8.14859089,3.71827244 11.7411386,3.71770102 13.949124,5.92796236 C16.1571094,8.13765227 16.1571094,11.7330412 13.949124,13.9427311 C11.7411386,16.152421 8.14859089,16.152421 5.94060548,13.9427311 L5.94060548,13.9427311 Z" id="path-5"></path>
        </defs>
        <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Our-Products-|-Our-Ecosystem--|--iPlatform2" transform="translate(-883.000000, -243.000000)">
                <g id="Secondary-nav/iPlatform" transform="translate(0.000000, 199.000000)">
                    <g id="Background-">
                        <use fill="black" fillOpacity="1" filter="url(#filter-2)" xlinkHref="#path-1"></use>
                        <use fill="#4CC2B8" fillRule="evenodd" xlinkHref="#path-1"></use>
                    </g>
                    <rect id="Rectangle" fill="#FFFFFF" x="355" y="34" width="570" height="45" rx="22.5"></rect>
                    <g id="Icon-/-Search" transform="translate(883.000000, 44.000000)">
                        <mask id="mask-4" fill="white">
                            <use xlinkHref="#path-3"></use>
                        </mask>
                        <g id="Container" fillRule="nonzero"></g>
                        <mask id="mask-6" fill="white">
                            <use xlinkHref="#path-5"></use>
                        </mask>
                        <g id="Search"></g>
                        <g id="Group" mask="url(#mask-6)" fill="#4CC2B8">
                            <g id="Container">
                                <rect x="0" y="0" width="120" height="116"></rect>
                            </g>
                        </g>
                    </g>
                </g>
                <rect id="Rectangle-Copy-40" stroke="#979797" x="0" y="-3" width="1440" height="3890"></rect>
            </g>
        </g>
    </svg>
  )
};

export default SearchIcon;
