import React from 'react'
import SearchIcon from '../../atoms/svg/SearchIcon'

interface Props {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  placeholder?: string;
  onSearch: any;
}

const Search: React.FC<Props> = ({onSearch, ...props}) => {
  return (
    <div className="flex bg-white max-w-xl w-full rounded-3xl h-11 items-center px-4">
      <input autoComplete="off"
       onKeyDown={(e:any) => {
        // if is enter
        if(e.keyCode === 13) onSearch()
      }} 
      style={{
        border: "none",
    }} 
    type="text" className="search-input bg-transparent outline-none border-none border-0 w-full grow" placeholder="Search" {...props}  />
      <div className="cursor-pointer" onClick={onSearch}><SearchIcon /></div>
    </div>
  )
}

export default Search;
