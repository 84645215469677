import React from 'react';
import PortableText from "react-portable-text";

export type tags =
|'h1'|'h2'|'h3'|'h4'|'h5'|'h6'|'normal'
|'blockquote'|'li'|'p'|'strong'|'span'|'urow'|'ul'|'ol'|'strong'|'paddedpara'
| 'externalLink' | 'internalLink' | 'normalrow' | 'ListRowrow';

type Prop = {
  content: any,
  className: string,
  template: {
    [key in tags]?: any
  }
}

const Index: React.FC<Prop> = ({ content, className, template = {} }) => (
  <>
    <PortableText content={content} serializers={template} className={className} />
  </>
);

export default Index;
