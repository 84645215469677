import React, { useState, useEffect } from 'react';
import CaretIcon from '../../atoms/svg/CaretIcon'
import Checkbox from '../../atoms/Checkbox';
import { IChip } from '../../../Context/IPlatformContext'
import { ISelectedChip } from '../ItemSelectWrapper'
import Close from '../../atoms/svg/Close'

interface IProp {
  chipListArr: IChip[]
  isInSelectedList: (item: any) => boolean
  toggleItemInList: (item: ISelectedChip) => void
  handleClearFilters: () => void
  setIsFilterOpen: (item: boolean) => void
  isFilterOpen: boolean
}

const FilterTab: React.FC<IProp> = ({ chipListArr, isInSelectedList, toggleItemInList, handleClearFilters, isFilterOpen, setIsFilterOpen  }) => {
  const [selectedList, setSelectedlist] = useState(0)
  const [className, setclassName] = useState('hidden')
  let body: any; 
  if(typeof window !== "undefined") {
    body = window.document.body;
  }
  const classList = ['max-h-screen', 'overflow-hidden']
 
  const isSelected = (a: number, b: number) => a===b;

  const closeMenu = () => {
    body.classList.remove(...classList)
    setclassName('animate__slideOutRight')
    setTimeout(() => {
      setIsFilterOpen(false)
      setclassName('hidden')
    }, 500);
  }

  useEffect(() => {
    if(isFilterOpen){
      setclassName('animate__slideInRight block')
      body.classList.add(...classList)
    }
  }, [isFilterOpen])

  const Filter=()=>(
    <>
    {chipListArr?.map((item, i) => (
      <React.Fragment key={i}>
        <div className='mb-4'>
          <div className={`${isSelected(selectedList, i) ? 'bg-viking text-white': 'bg-dark-grey-28 overflow-hidden cursor-pointer'} 
            rounded py-2 px-4 flex justify-between `} onClick={() => setSelectedlist(i)}>
            <p className='flex justify-between cursor-pointer relative text-left text-[18px] leading-7' >{item?.title?.replace(':', '')}</p>
            <CaretIcon gray={isSelected(selectedList, i)} rotate={!isSelected(selectedList, i)}/>
          </div>
          <div className={`${isSelected(selectedList, i) ? 'shadow-iplatformcard px-4 pb-4': 'max-h-0 overflow-hidden' }
            transition-all text-black `}>
              <p className='text-blue-stone underline uppercase text-[13px] text-left pt-3 cursor-pointer' onClick={handleClearFilters}>clear</p>
              {item?.list?.map((list, k) => (
                <div key={k} className='flex items-end'>
                  <Checkbox
                    className={`${isInSelectedList(list) ? 'border-viking': 'border-dark-grey'} border-[1px] text-sm`}
                    type={'checkbox'}
                    name={list?.text}
                    value={list?.text}
                    handleChange={() => toggleItemInList(list) } 
                    label={<p className='text-[15px] leading-4 text-left ml-2 whitespace-pre-line'>{list?.text}</p>}
                    defaultChecked={isInSelectedList(list)}
                    checkIconFill={'#4CC2B8'}
                  />
                </div>
              ))}
          </div>
        </div>
      </React.Fragment>
    ))}
    </>
  )

  return (
    <>
      <div className={`animate__animated animate__faster ${className} fixed z-[100] right-0 bottom-0
      top-0 left-8 bg-white shadow-left py-6 h-screen lg:hidden overflow-auto px-4`}>
        <div className="flex items-center justify-between w-full capitalize" onClick={closeMenu}>
          <p>FILTERS:</p>
          <Close />
        </div>
        <div className='mt-6'>
          <Filter />
        </div>
      </div>
    <div className='hidden lg:block'>
      <Filter />
    </div>
    </>
  )
};

export default FilterTab;
