import React from 'react';
import Image from '../../atoms/Image'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import { replaceLinks } from '../../../utils/service'
import { CardLink } from '../../../types';
interface ICard {
  imgUrl: any,
  imgAlt: string,
  cardTitle: string,
  linkList: CardLink[]
  _bottomStyle?: string
  className?: string
}
const OverviewCardWithLink: React.FC<ICard> = ({ imgUrl, imgAlt, cardTitle, linkList, _bottomStyle, className, ...rest }) => {
  return (
    <article className={`border border-white-smoke shadow-lg flex flex-col ${className}`} {...rest}>
      <div className="flex justify-center h-60">
        <Image alt={imgAlt} src={imgUrl} className="object-cover w-full" />
      </div>
      <div className={`py-10 w-10/12 mx-auto ${_bottomStyle}`}>
        <Text type="H4" className="font-normal font-montserrat text-gray-800 mb-2">{cardTitle}</Text>
        <ul>
          {linkList.map((link, index) => (
            <li key={index} className="py-3 border-b border-white-smoke text-sm text-blue-stone font-medium">
              {link?.navanchor ? <Link type={link?.linkURL ? 'external' : 'internal'} to={link.linkURL ? link.linkURL : replaceLinks(`/${link?.pageLink?.slug?.current}${link?.navanchor ? `#${link?.navanchor}`: ''}`)}>{link.linkTitle}</Link> :
              <Link type={link.linkURL ? 'external' : 'internal'} to={link.linkURL ? link.linkURL : replaceLinks(`/${link?.pageLink?.slug?.current}`)}>{link.linkTitle}</Link>
              }
            </li>
          ))}
        </ul>
      </div>
    </article>
  )
}

export default OverviewCardWithLink