import React from 'react'

const Next = ({ disabled }: {disabled: boolean}) => {
  return (
    <div className="next">
      <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.589966 10.59L5.16997 6L0.589966 1.41L1.99997 0L7.99997 6L1.99997 12L0.589966 10.59Z" fill="#181819" fillOpacity={disabled ? "0.32" : "0.9"} />
      </svg>
    </div>


  )
}

export default Next
