import React from 'react';
import { useAppListContext } from '../../../Context/AppListContext';
import { IPlatformCardProps } from '../Card/IPlatformCard';
import Remove from './Remove';

const ListAddIcon: React.FC<IPlatformCardProps> = (props) => {
  const { onAppListClick, isInAppList } = useAppListContext();
  const handleClick = () => {
    onAppListClick && onAppListClick(props as IPlatformCardProps)
  }
  return (
    <div>
      {isInAppList && isInAppList(props?.uniqueReference) ? (<div onClick={handleClick} className="flex cursor-pointer">
        <Remove />
        <p className="mb-0 ml-2 inline text-sm text-red-600 font-semibold self-center border-b border-red-600 pb-2">REMOVE</p>
      </div>)
        : (<div onClick={handleClick} className="cursor-pointer"><svg width="43px" height="43px" viewBox="0 0 43 43" version="1.1">
          <g id="-Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Card/App-2" transform="translate(-288.000000, -476.000000)" fillRule="nonzero">
              <g id="Group" transform="translate(288.000000, 476.000000)">
                <path d="M16.4482353,38 L4.55882353,38 C2.046,38 0,35.9715238 0,33.4761905 L0,4.52380952 C0,2.02847619 2.046,0 4.55882353,0 L26.4411765,0 C28.954,0 31,2.02847619 31,4.52380952 L31,15.4895238 C31,15.9889524 30.5915294,16.3942857 30.0882353,16.3942857 C29.5849412,16.3942857 29.1764706,15.9889524 29.1764706,15.4895238 L29.1764706,4.52380952 C29.1764706,3.02733333 27.9492353,1.80952381 26.4411765,1.80952381 L4.55882353,1.80952381 C3.05076471,1.80952381 1.82352941,3.02733333 1.82352941,4.52380952 L1.82352941,33.4761905 C1.82352941,34.9726667 3.05076471,36.1904762 4.55882353,36.1904762 L16.4482353,36.1904762 C16.9515294,36.1904762 17.36,36.5958095 17.36,37.0952381 C17.36,37.5946667 16.9515294,38 16.4482353,38 Z" id="Path" fill="#404040"></path>
                <path d="M24.0909091,16 L5.90909091,16 C5.40727273,16 5,15.552 5,15 C5,14.448 5.40727273,14 5.90909091,14 L24.0909091,14 C24.5927273,14 25,14.448 25,15 C25,15.552 24.5927273,16 24.0909091,16 Z" id="Path" fill="#747371"></path>
                <path d="M17.1428571,23 L6.85714286,23 C6.384,23 6,22.552 6,22 C6,21.448 6.384,21 6.85714286,21 L17.1428571,21 C17.616,21 18,21.448 18,22 C18,22.552 17.616,23 17.1428571,23 Z" id="Path" fill="#747371"></path>
                <path d="M15.0833333,9 L5.91666667,9 C5.41066667,9 5,8.552 5,8 C5,7.448 5.41066667,7 5.91666667,7 L15.0833333,7 C15.5893333,7 16,7.448 16,8 C16,8.552 15.5893333,9 15.0833333,9 Z" id="Path" fill="#747371"></path>
                <path d="M31.5,43 C25.1590769,43 20,37.8409231 20,31.5 C20,25.1590769 25.1590769,20 31.5,20 C37.8409231,20 43,25.1590769 43,31.5 C43,37.8409231 37.8409231,43 31.5,43 Z M31.5,21.7692308 C26.1339231,21.7692308 21.7692308,26.1356923 21.7692308,31.5 C21.7692308,36.8643077 26.1339231,41.2307692 31.5,41.2307692 C36.8660769,41.2307692 41.2307692,36.8643077 41.2307692,31.5 C41.2307692,26.1356923 36.8660769,21.7692308 31.5,21.7692308 Z" id="Shape" fill="#4CC2B8"></path>
                <g transform="translate(25.500000, 25.500000)" fill="#4CC2B8" id="Path">
                  <path d="M6,12 C5.448,12 5,11.616 5,11.1428571 L5,0.857142857 C5,0.384 5.448,0 6,0 C6.552,0 7,0.384 7,0.857142857 L7,11.1428571 C7,11.616 6.552,12 6,12 Z"></path>
                  <path d="M11.1428571,7 L0.857142857,7 C0.384,7 0,6.552 0,6 C0,5.448 0.384,5 0.857142857,5 L11.1428571,5 C11.616,5 12,5.448 12,6 C12,6.552 11.616,7 11.1428571,7 Z"></path>
                </g>
                <path d="M19.1666667,23 L5.83333333,23 C5.37333333,23 5,22.552 5,22 C5,21.448 5.37333333,21 5.83333333,21 L19.1666667,21 C19.6266667,21 20,21.448 20,22 C20,22.552 19.6266667,23 19.1666667,23 Z" id="Path-Copy" fill="#4CC2B8"></path>
                <path d="M17.2777778,30 L5.72222222,30 C5.32355556,30 5,29.552 5,29 C5,28.448 5.32355556,28 5.72222222,28 L17.2777778,28 C17.6764444,28 18,28.448 18,29 C18,29.552 17.6764444,30 17.2777778,30 Z" id="Path-Copy-2" fill="#4CC2B8"></path>
                <path d="M25.7777778,16 L6.22222222,16 C5.54755556,16 5,15.552 5,15 C5,14.448 5.54755556,14 6.22222222,14 L25.7777778,14 C26.4524444,14 27,14.448 27,15 C27,15.552 26.4524444,16 25.7777778,16 Z" id="Path-Copy-3" fill="#4CC2B8"></path>
                <path d="M25.7777778,9 L6.22222222,9 C5.54755556,9 5,8.552 5,8 C5,7.448 5.54755556,7 6.22222222,7 L25.7777778,7 C26.4524444,7 27,7.448 27,8 C27,8.552 26.4524444,9 25.7777778,9 Z" id="Path-Copy-4" fill="#4CC2B8"></path>
              </g>
            </g>
          </g>
        </svg></div>)}
    </div>
  )
}

export default ListAddIcon
