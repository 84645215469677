import React from 'react'
import Button from '../../atoms/Button'
import Link from '../../atoms/Link';
import Text from '../../atoms/Text'
import ListAddIcon from '../ListComp/ListAddIcon';
import Image from '../../atoms/Image';

export interface IPlatformCardProps {
  type: "CATEGORY" | "APPLICATION" | "COMING_SOON";
  title: string;
  imageurl?: string;
  description: string;
  PASIntegrated?: string;
  category?: string;
  uniqueReference: string;
  shouldHaveAddButton?: boolean;
  icon?: string;
  hideButtons?: boolean;
  view?: 'grid' | 'list';
  className?: string;
  hideReadMore?: boolean;
  hidePASIntegrated?: boolean;
  hideCategory?: boolean;
  ctaUrl?: string;
  readMoreNotVisible?: boolean;
  shouldUseMaxHeight?: boolean;
  detail: {
    children: {
      text: string;
    }[];
  }[]
}

const IPlatformCard: React.FC<IPlatformCardProps> = (props) => {
  const {
    type,
    title,
    imageurl,
    description,
    PASIntegrated,
    category,
    hideButtons,
    shouldHaveAddButton,
    icon,
    view = 'grid',
    className="",
    hideReadMore,
    hidePASIntegrated,
    hideCategory,
    ctaUrl,
    readMoreNotVisible,
    shouldUseMaxHeight
  } = props;
  
  const categoryType = type === 'CATEGORY';
  const gridType = {
    rootStyle: { minHeight: categoryType ? "500px" : "350px" },
    rootClass: `p-4 shadow-iplatformcard max-w-sm flex flex-col bg-white mx-auto w-full ${shouldUseMaxHeight ? 'sm:max-h-[650px]' : ''}`,
    imageWrapClass: "h-32 flex justify-center items-center border-b border-solid border-dark-grey-28 mb-6 pb-8",
    categoryClass: "mb-4",
    categoryText: "my-0 py-0 leading-6",
    PASClass: "my-4 flex",
    readMoreClass: `w-42 h-10 my-0 rounded-3xl hover:bg-viking text-sm md:px-8 ${readMoreNotVisible ? "invisible" : ""}`,
    addToListClass: "hidden",
    iconWrapClass: "",
    content: "flex flex-col flex-grow",
    title: "font-light mb-6 mt-4 h-28"
  }
  const listType = {
    rootStyle: {},
    rootClass: `px-6 shadow-iplatformcard w-full flex flex-row items-center mb-8 bg-white hover:cursor-pointer ${category || PASIntegrated ? "h-60 md:56" : "h-56 md:52 lg:h-48"}`,
    imageWrapClass: "w-1/4 h-44 flex justify-center items-center border-r border-solid border-dark-grey-28 mr-4 px-8",
    categoryClass: "flex mb-2",
    categoryText: "my-0 py-0 leading-6 overflow-x-hidden whitespace-nowrap overflow-ellipsis",
    PASClass: "my-2 flex",
    readMoreClass: "hidden",
    addToListClass: "my-0 py-0 leading-6 text-viking mr-4 whitespace-nowrap font-semiBold",
    iconWrapClass: "w-1/4 h-44 flex items-center justify-center border-r border-solid border-dark-grey-28 mr-4",
    content: `${categoryType ? "w-3/4 flex-grow" : "w-3/5"} p-4`,
    title: "font-light mb-4 mt-4",
  };
  const typeStyle = view === 'grid' ? gridType : listType;

  const Component = () => (
    <div style={typeStyle.rootStyle} className={`hover:shadow-iplatformcard-hov ${typeStyle.rootClass} ${className}`}>
      {icon && <div className={typeStyle.iconWrapClass}><Image src={icon} className="w-12 h-12 mb-8 mt-4" alt="" /></div>}
      {imageurl && <div className={typeStyle.imageWrapClass}><Image src={imageurl} className="w-full h-20 object-contain" alt="" /></div>}
      <div className={typeStyle.content}>
        <Text type="H3" className={typeStyle.title} >{title}</Text>
        <div className="flex-grow flex flex-col">
          {category && !hideCategory &&
            <div className={typeStyle.categoryClass}>
              <Text type="P" ptype="p-base" className="my-0 py-0 leading-6 mr-2">Category:</Text>
              <Text type="P" ptype="p-base" className={typeStyle.categoryText} >{category}</Text>
            </div>
          }
          <Text type="P" ptype="p-base" className="p-base flex-grow mb-4 truncated" >{description || ''}</Text>
          {PASIntegrated && !hidePASIntegrated &&
            <div className={typeStyle.PASClass}>
              <Text type="P" ptype="p-base" className="my-0 py-0 leading-6 mr-2 font-semibold">PAS integrated:</Text>
              <Text type="P" ptype="p-base" className="my-0 py-0 leading-6 font-light" >{PASIntegrated}</Text>
            </div>
          }
        </div>
      </div>

      {!hideButtons && <div className="flex flex-wrap gap-y-3 justify-between items-center my-4">
        {!hideReadMore && <Link to={ctaUrl || ''} ><Button color="btn-primary" size="btn-small" className={typeStyle.readMoreClass}>Read More</Button></Link>}
        {shouldHaveAddButton && <div className="flex items-center"><Text type="P" ptype="p-base" className={typeStyle.addToListClass}>Add to List:</Text><ListAddIcon {...props} /></div>}
      </div>}
    </div>
  );

  return view === 'grid' ? <Component /> : <Link to={ctaUrl || ''} ><Component /></Link>;
}

export default IPlatformCard
