import React, { ReactElement, useEffect, useState } from 'react'
import Text from '../../atoms/Text'
import GridIcon from './GridIcon'
import ListIcon from './ListIcon';

interface Props {
  setView: (view: "list" | "grid") => void;
  activeView: "list" | "grid";
}

function ViewComp({activeView, setView}: Props): ReactElement {
  const isGrid = activeView === "grid";
  const setGrid = () => {    
    setView("grid")
  };

  const setList = () => {
    setView("list")
  };

  const handleResize = () => {
    if (window.innerWidth < 768 && !isGrid) {
      setView("grid")
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className="md:flex hidden items-center">
      <Text type="P" ptype="sm-light" className="mr-2">View</Text>
      <div className="flex items-center">
        <div className="mx-4 cursor-pointer" onClick={setGrid} ><GridIcon  active={isGrid} /></div>
        <div className="cursor-pointer hidden md:block" onClick={setList}><ListIcon active={!isGrid}/></div>
      </div>
    </div>
  )
}

export default ViewComp
