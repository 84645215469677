import React from 'react';
import Link from '../../atoms/Link'


interface ICard {
  className?: string
  linkTo: string
}

const ContentPageCard: React.FC<ICard> = ({ children, linkTo, className, ...rest }) => {
  return (
    <Link to={linkTo} className={`h-133 ring-1 ring-white-smoke flex py-8 px-5 text-base transition duration-500 text-dove-grey font-semibold hover:text-viking hover:border-viking hover:border-l-8 active:border-viking active:border-l-8 ${className
      }`} {...rest}>
      {children}
    </Link>
  )
}

export default ContentPageCard