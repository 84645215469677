import React from "react"
import ProductCard from "./ProductCard"

import { IProductCardTitleWithContent } from '../../../types/components'

const TitleWithContentCard: React.FC<IProductCardTitleWithContent> = (
  { imgUrl, imgAlt, cardTitle, _bottomStyle = '', className = '', button, cardContent, readMoreLink, subheading, ...rest }) => (
    <ProductCard imgAlt={imgAlt} imgUrl={imgUrl} cardTitle={cardTitle} button={button} subheading={subheading}
  _bottomStyle={_bottomStyle} className={className} cardContent={cardContent} cardSubtitle='' readMoreLink={readMoreLink} {...rest} />
)

export default TitleWithContentCard;
