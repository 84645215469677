import React from 'react';
import Image from '../../atoms/Image'
import Text from '../../atoms/Text'
import Link from '../../atoms/Link'
import { Card } from '../../../types'
import { replaceLinks } from '../../../utils/service'

import PortableText from '../../atoms/PortableText'

interface IProp {
  imgUrl: any
  imgAlt: string
  _bottomStyle?: string,
  cardTitle: string
  cardTitleClassName?: string
  cardSubtitle: string
  className?: string
  cardContent: any
  readMoreLink: string
  subheading?: string
  button: Card['button']
}

const ProductCard: React.FC<IProp> = ({ 
  imgUrl, imgAlt = '', cardTitle, _bottomStyle = '', button, className = '', cardContent, cardSubtitle, readMoreLink = '', subheading, 
  cardTitleClassName, ...rest }) => {
  const _readMoreLink = button ? replaceLinks(`/${button?.buttonLink?.slug?.current}`) : readMoreLink;
  const readMore = button?.buttonText || 'Read More'
  return (
    <article className={`border border-white-smoke shadow-lg flex flex-col justify-between pb-5 ${className}`} {...rest}>
      <div className="">
        <div className="flex justify-center h-60">
          <Image alt={imgAlt} src={imgUrl} className="object-cover w-full" />
        </div>
        <div className={`p-4 ${_bottomStyle}`}>
          <Text type="H4" className={`font-normal  mb-2 ${cardTitleClassName ?? 'text-gray-800'}`}>{cardTitle}</Text>
          {subheading ? <Text type="P" ptype='p-base' className="font-normal text-gray-800 mb-2">{subheading}</Text> : ''}
          {cardSubtitle && (<Text type="P" ptype="sm-light" className="my-4"><span className="font-medium">Pillar:</span> {cardSubtitle}</Text>)}
          {cardContent && (typeof cardContent === 'string' ? (<Text type="P" ptype="sm-light">{cardContent}</Text>) : 
            <PortableText className="text-lg" content={cardContent} template={{
              normal: (props: any) => <Text type="P" ptype="p-base" className="text-dove-grey">{props.children}</Text>,
              h2: (props: any) => <Text type="H2" className="text-charcoal font-normal">{props.children}</Text>,
              h1: (props: any) => <Text type="H1" className="text-charcoal font-normal">{props.children}</Text>,
              blockquote: (props: any) => <Text type="Quote"  className="">{props.children}</Text>,
            }} />)
          }
        </div>
      </div>
      <div className="px-4 py-2">
        {_readMoreLink && <Link to={_readMoreLink} 
        className="bg-blue-stone rounded-full text-white py-2 border 
        border-blue-stone text-center block hover:bg-viking hover:border-viking text-sm
        transition-all duration-700 lg:w-7/12">{readMore}</Link> }
      </div>
    </article>
  )
}

export default ProductCard