import React from 'react'
import Container, { CategoryContainerProps } from '../PlatformCategories';
interface IPlatformSearchResultsProps extends CategoryContainerProps {
  content: any;
  FilterTab: any;
}

const PlatformSearchResults = ({ view, CustomHeader, content, FilterTab }: IPlatformSearchResultsProps) => {  
  return (
    <div className="">
      <Container className="pb-8" Header={CustomHeader} records={content} view={view} FilterTab={FilterTab}  />
    </div>
  )
};
export default PlatformSearchResults
