import React from "react"

interface IVideo {
  videoUrl: string,
  className?: string
  title: string
  options?: {
    autoplay?: boolean,
    loop?: boolean,
    modestbranding?: boolean,
    disablekb?: boolean,
    controls?: boolean
  }
  navanchor?: string | null
}

const Video: React.FC<IVideo> = ({
  videoUrl,
  title,
  className='',
  navanchor,
  options = {
    autoplay: false,
    loop: false,
    modestbranding: true,
    disablekb: false,
    controls: false
  },
  ...rest }) => {

  return (
    <section className={`max-w-screen-xxl lg:w-6/12 md:w-7/12 w-11/12 mx-auto ${className}`} id={navanchor || ''}>
      <iframe
        title={title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture fullscreen"
        height="315"
        src={`${videoUrl}?rel=0&amp;autoplay=${options.autoplay ? 1 : 0}&amp;loop=${options.loop ? 1 : 0}&amp;modestbranding=${options.modestbranding ? 1 : 0}&amp;disablekb=${options.disablekb ? 1 : 0}&amp;controls=${options.controls ? 1: 0}`}
        loading="lazy"
        className="w-full h-96 md:object-cover object-center"
        allowFullScreen={true}
        {...rest}
      />
    </section>
  )
}

export default Video