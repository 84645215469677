import React, { useCallback, useEffect } from 'react'



const usePaginate = ( pageSize: number = 12, callBackAfterSetPage: any) => {
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(0);
  const [paginated, setPaginated] = React.useState<any[]>([]);
  const [raw, setRaw] = React.useState<any[]>([]);

  const processPagination = useCallback((data: any[] = []) => {
    setRaw(data);
    setCount(data.length);
    setPage(1)
    const paginated = data.slice(0, pageSize);
    setPaginated(paginated);   
    callBackAfterSetPage?.()
  }, [setRaw, setCount, setPaginated]);

  const paginate = useCallback(() => {
    if(page < 1) {
      setPage(1); 
      return;
    }
    const offset = (page - 1) * pageSize;
    let currentPosition = offset + pageSize;
    currentPosition = currentPosition > count ? count : currentPosition;
    // check if raw has enough data to paginate
    if (raw.length < offset) {
      return;
    }
    const newPaginated = raw.slice(offset, currentPosition);
    setPaginated(newPaginated);
    callBackAfterSetPage?.()
  }, [page, pageSize, raw, setPaginated]);

  useEffect(() => {
    if(raw.length > 0) {
      paginate();
    }
  }, [page]);


  return ({
    count,
    pageSize,
    setPage,
    page,
    processPagination,
    paginated,
    raw
  })
}

export default usePaginate
