import React from 'react';
import Image from '../../atoms/Image'
interface IProp {
  imgUrl: any
  imgAlt: string
  className?: string
}

const LogoOnlyContentCard: React.FC<IProp> = ({
  imgUrl, imgAlt = '', className = '', ...rest }) => {

  return (
    <article className={`mb-6 hover:shadow-logoTeaserCard col-12 col-md-4 col-sm-6 ${className}`} {...rest}>
      <Image alt={imgAlt} src={imgUrl} className="md:h-logo-teaser p-4 xxl:p-10 object-contain h-full w-full" />
    </article>
  )
}

export default LogoOnlyContentCard;
